import React from "react";
import { NavLink } from "react-router-dom";
import { useEmployee } from "../../context/EmployeeContext";
import Heading from "../../elements/Heading";
import Subtext from "../../elements/Subtext";
import useGetListForEmployee from "../../hooks/useGetListForEmployee";

const CheckTaskListPage = () => {
  const { employeeCred } = useEmployee();

  const { taskList, loading } = useGetListForEmployee(employeeCred);

  return (
    <>
      {loading ? (
        <div className="p-8">Cargando...</div>
      ) : (
        <div className="flex flex-col gap-4 p-8">
          <Heading>
            Selecciona una lista de tareas para {employeeCred.puesto}:
          </Heading>
          <div className="flex h-[90%] flex-col gap-4 overflow-y-scroll p-2">
            {taskList ? (
              taskList.map((taskListItem) => {
                const doneTasks = taskListItem.tareas.filter(
                  (task) => task.done === true,
                );
                return (
                  <NavLink
                    key={taskListItem.id}
                    to={"/hola/marcartareas"}
                    state={{ list: taskListItem, user: employeeCred }}
                  >
                    <div className="flex w-[100%] cursor-pointer select-none justify-between rounded bg-gray-200 p-4 text-sm shadow-sm transition-all hover:translate-y-1 hover:bg-gray-100 md:text-lg">
                      <p>{taskListItem.nombreDeLista}</p>
                      <p className="invisible md:visible">
                        Tareas completadas: {doneTasks.length} de{" "}
                        {taskListItem.tareas.length}
                      </p>
                    </div>
                  </NavLink>
                );
              })
            ) : (
              <Subtext>No hay listas de tareas aún</Subtext>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default CheckTaskListPage;
