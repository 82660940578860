import { lazy } from "react";

export const HomeDashboard = lazy(() => import("../components/HomeDashboard"));
export const ColdBar = lazy(() => import("../components/pages/ColdBar"));
export const Hotbar = lazy(() => import("../components/pages/Hotbar"));
export const Entries = lazy(() => import("../components/pages/Entries"));
export const Beverages = lazy(() => import("../components/pages/Beverages"));
export const Menu = lazy(() => import("../components/pages/Menu"));
export const Profile = lazy(() => import("../components/Profile"));
export const TasksPage = lazy(() => import("../components/pages/TasksPage"));
export const NewTaskPage = lazy(
  () => import("../components/pages/NewTaskPage"),
);
export const UnassignedUsers = lazy(
  () => import("../components/pages/UnassignedUsers"),
);
export const HelperUsers = lazy(
  () => import("../components/pages/HelperUsers"),
);
export const ManagerUsers = lazy(
  () => import("../components/pages/ManagerUsers"),
);
export const EmployeeUsers = lazy(
  () => import("../components/pages/EmployeeUsers"),
);
export const AdminUsers = lazy(() => import("../components/pages/AdminUsers"));
export const NewUserPage = lazy(
  () => import("../components/pages/NewUserPage"),
);
export const EditUser = lazy(() => import("../components/EditUser"));
export const ViewTaskList = lazy(() => import("../components/ViewTasksList"));
export const DeleteTaskList = lazy(
  () => import("../components/DeleteTaskList"),
);
export const EditTaskList = lazy(() => import("../components/EditTaskList"));
export const EditTasksPage = lazy(
  () => import("../components/pages/EditTasksPage"),
);
export const HistoryTaskPage = lazy(
  () => import("../components/pages/HistoryTaskPage"),
);
