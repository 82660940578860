import React from "react";
import LoginForm from "./LoginForm";

const AdminGate = () => {
  return (
    <div className="mt-8 flex justify-center">
      <div className="rounded-xl p-4 shadow-xl md:w-[50%]">
        <LoginForm />
      </div>
    </div>
  );
};

export default AdminGate;
