import React from "react";
import { NavLink } from "react-router-dom";
import { sideBarLinks as sidebarOptions } from "./sideBarLinks";

const NavSidebaritems = ({ closeDrawer }) => {
  return (
    <>
      {sidebarOptions.map((option, index) => (
        <div key={index} className="mb-4 select-none">
          <h2 className="mb-1 ml-4 text-violet-500">{option.title}</h2>
          <ul className="flex flex-col">
            {option.items.map((item, itemIndex) => (
              <NavLink
                key={itemIndex}
                className={({ isActive }) =>
                  isActive
                    ? "mb-1 ml-8 w-fit rounded border-violet-700 bg-violet-700/70 px-3 py-1 text-white"
                    : "mb-1 ml-8 w-fit rounded px-3 py-1 text-violet-700 hover:border-violet-700 hover:bg-violet-700/70  hover:text-white"
                }
                to={`/${item.href}`}
                onClick={closeDrawer}
              >
                {item.label}
              </NavLink>
            ))}
          </ul>
        </div>
      ))}
    </>
  );
};

export default NavSidebaritems;
