import React from "react";
import { MdOutlineDownloadDone } from "react-icons/md";
import useFormatTimestampDateShort from "../hooks/useFormatTimestampDateShort";

const ShowTaskInfo = ({ handleClose, selectedTask }) => {
  const firebaseTimestamp = selectedTask.timeOfCompletion;
  const { date } = useFormatTimestampDateShort(firebaseTimestamp);
  const user = selectedTask.doneBy;

  return (
    <div className="flex flex-col items-center gap-4 p-2">
      <MdOutlineDownloadDone color="green" size={50} />
      Tarea completada
      <div className="flex flex-col gap-2 md:w-[500px]">
        <p>Tarea: {selectedTask.name}</p>
        <p>Descripción: {selectedTask.desc}</p>
        <p>Nota: {selectedTask.note}</p>
        <p>
          Hecho por: {user.nombre} {user.apellido}
        </p>
        <p>Fecha: {date} </p>
      </div>
      <button
        onClick={handleClose}
        type="button"
        className="btn btn-success w-[50%]"
      >
        Ok
      </button>
    </div>
  );
};

export default ShowTaskInfo;
