import { useEffect, useState } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  Timestamp,
  updateDoc,
  doc as firestoreDoc,
} from "firebase/firestore";
import { db } from "../firebase/FirebaseConfig";

const useGetListForEmployee = (employeeCred) => {
  const [taskList, setTaskList] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!employeeCred || !employeeCred.rol || !employeeCred.puesto) return;

    const fetchTaskList = async () => {
      try {
        const colRef = collection(db, "listas");
        const q = query(
          colRef,
          where("usuarios", "array-contains", employeeCred.puesto),
        );

        const search = await getDocs(q);

        const dataArray = await Promise.all(
          search.docs.map(async (document) => {
            const tasksRef = collection(db, `listas/${document.id}/tareas`);
            const listData = document.data();
            const presentDate = Timestamp.now();
            const presentDateObj = presentDate.toDate();

            // Calcular la próxima fecha de reseteo
            const lastResetDate = listData.fecha.toDate();
            let shouldReset = false;

            switch (listData.frecuencia) {
              case "Diario":
                shouldReset =
                  presentDateObj.setHours(0, 0, 0, 0) >
                  lastResetDate.setHours(0, 0, 0, 0);
                break;

              case "Semanal":
                shouldReset =
                  presentDateObj >
                  new Date(lastResetDate.getTime() + 7 * 24 * 60 * 60 * 1000);
                break;

              case "Quincenal":
                shouldReset =
                  presentDateObj >
                  new Date(lastResetDate.getTime() + 14 * 24 * 60 * 60 * 1000);
                break;

              case "Mensual":
                shouldReset =
                  presentDateObj.getFullYear() > lastResetDate.getFullYear() ||
                  (presentDateObj.getFullYear() ===
                    lastResetDate.getFullYear() &&
                    presentDateObj.getMonth() > lastResetDate.getMonth());
                break;

              case "Anual":
                shouldReset =
                  presentDateObj.getFullYear() > lastResetDate.getFullYear();
                break;

              default:
                break;
            }

            // Lógica para sobrescribir tareas
            if (shouldReset) {
              const tasksSnap = await getDocs(tasksRef);

              // Resetear tareas
              const resetTasksPromises = tasksSnap.docs.map((taskDoc) => {
                const taskRef = firestoreDoc(
                  db,
                  `listas/${document.id}/tareas/${taskDoc.id}`,
                );
                return updateDoc(taskRef, { done: false, doneBy: null });
              });

              await Promise.all(resetTasksPromises);

              // Actualizar fecha de la lista a la fecha actual
              const listRef = firestoreDoc(db, `listas/${document.id}`);
              await updateDoc(listRef, { fecha: presentDate });
            }

            // Obtener tareas actualizadas
            const tasksSnap = await getDocs(tasksRef);
            const tasks = tasksSnap.docs.map((taskDoc) => ({
              id: taskDoc.id,
              ...taskDoc.data(),
            }));

            return {
              id: document.id,
              ...listData,
              tareas: tasks,
            };
          }),
        );

        setTaskList(dataArray);
      } catch (error) {
        console.error("Error al obtener las listas de tareas:", error);
        setError(true);
        setErrorMessage("Error al obtener las listas de tareas.");
      } finally {
        setLoading(false);
      }
    };

    fetchTaskList();
  }, [employeeCred]);

  return { taskList, loading, error, errorMessage };
};

export default useGetListForEmployee;
