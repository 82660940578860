import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEmployee } from "../context/EmployeeContext";
import Heading from "../elements/Heading";
import NumericInput from "../elements/NumericInput";
import fetchEmployees from "../functions/fetchEmployees";

const EmployeeGate = () => {
  const [number, setNumber] = useState("");
  const [password, setPassword] = useState("");
  const [credentialError, setCredentialError] = useState(false);
  const navigate = useNavigate();
  const { setLoggedAsEmployee, setEmployeeCred, employeeCred } = useEmployee();

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        fetchEmployees(
          number,
          password,
          setCredentialError,
          navigate,
          setLoggedAsEmployee,
          setEmployeeCred,
          employeeCred,
        );
      }}
      className="flex flex-col gap-8"
    >
      <div className="flex flex-col gap-2 ">
        {credentialError && (
          <p className="text-red-500">
            Error de número de empleado o contraseña
          </p>
        )}
        <Heading>Ingrese su número de empleado:</Heading>
        <NumericInput value={number} onChange={setNumber} />
      </div>
      <div>
        <Heading>Ingrese contraseña:</Heading>
        <input
          className="w-[100%] rounded border p-2 focus:border-blue-500 focus:outline-none"
          placeholder="Contraseña"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <button className="btn btn-primary">Entrar</button>
    </form>
  );
};

export default EmployeeGate;
