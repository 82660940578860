import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase/FirebaseConfig";

const fetchEmployees = async (
  number,
  password,
  setCredentialError,
  navigate,
  setLoggedAsEmployee,
  setEmployeeCred,
  employeeCred,
) => {
  const colRef = collection(db, "empleados");
  const q = query(
    colRef,
    where("id", "==", number),
    where("password", "==", password),
  );
  const search = await getDocs(q);
  const dataArray = search.docs.map((doc) => ({
    modifier: doc.id,
    ...doc.data(),
  }));

  if (search.docs.length > 0) {
    setEmployeeCred(dataArray.find((employee) => employee.id === number));
    setLoggedAsEmployee(true);
    navigate("tareasasignadas");
  } else {
    setCredentialError(true);
  }
};

export default fetchEmployees;
