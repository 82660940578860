import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../firebase/FirebaseConfig";

const ResetPassword = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert(
      `Se ha enviado un correo para reestablecet la contraseña de ${email}, revisa tu bandeja de entrada`,
    );
  } catch (error) {
    if (error.message === "Firebase: Error (auth/missing-email).") {
      alert("Debes llenar el campo de Correo para reestablecer tu contraseña");
    } else {
      alert("Ha ocurrido un error desconocido, intente más tarde");
    }
  }
};

export default ResetPassword;
