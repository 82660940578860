import React from "react";
import { Navigate } from "react-router-dom";
import { useEmployee } from "../context/EmployeeContext";

const PrivateEmployeeRoutes = ({ children }) => {
  const { employeeCred } = useEmployee();

  if (employeeCred) {
    return children;
  } else {
    return <Navigate replace to="/hola" />;
  }
};

export default PrivateEmployeeRoutes;
