import { signOut } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, NavLink } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { auth, db } from "../firebase/FirebaseConfig";
import Logo from "../images/logo_mariscos.png";
import { useReloadContext } from "../context/ReloadContext";
import { doc, getDoc } from "firebase/firestore";

const Navbar = () => {
  const { user } = useAuth();
  const [menuOpen, setMenuOpen] = useState(false);
  const [userName, setUserName] = useState("usuario");
  const [userPhoto, setUserPhoto] = useState("");
  const { reload, setReload, defaultAvatar, defaultName } = useReloadContext();

  useEffect(() => {
    const setProfileData = async () => {
      try {
        const docRef = doc(db, "usuarios", user.uid);
        const userDoc = await getDoc(docRef);
        const loadedData = userDoc.data();

        const namesArray = loadedData.nombre.trim().split(" ");
        const firstName = namesArray[0];

        setUserName(firstName);
        setUserPhoto(loadedData.foto);
      } catch (error) {
        console.log(error);
      }
    };

    if (user) setProfileData();
  }, [user]);

  useEffect(() => {
    const reloadProfile = async () => {
      if (user) {
        try {
          const docRef = doc(db, "usuarios", user.uid);
          const userDoc = await getDoc(docRef);
          const loadedData = userDoc.data();

          const namesArray = loadedData.nombre.trim().split(" ");
          const firstName = namesArray[0];

          setUserName(firstName);
          setUserPhoto(loadedData.foto);
        } catch (error) {
          console.log(error);
        }
      }
    };

    if (reload) {
      reloadProfile();
      setReload(false);
    }
  }, [user, reload, setReload]);


  const navigate = useNavigate();

  
const NavMenuLinks  =[
  {
    name: "Home",
    href: "/",
  },
  {
    name: "Perfil",
    href: "/perfil",
  },
  {
    name: "Cerrar sesión",
    href: "/logout",
  },
];


  const handleLogout = async () => {
    await signOut(auth);
    navigate("/hola", { replace: true });
  };
  return (
    <div className="navbar fixed top-0 z-[1100] select-none bg-white px-8 py-4">
      <div className="flex-1 md:gap-16">
        {user && user.emailVerified ? (
          <Link className="flex items-center gap-4 text-sm" to="/">
            <div className="hidden items-center gap-4 md:flex">
              <img src={Logo} width="75px" alt="logo" />
              <p className="text-base font-bold">Administración</p>
            </div>
          </Link>
        ) : (
          <div className=" select-none items-center gap-4 md:flex">
            <img src={Logo} width="75px" alt="logo" />
            <p className="text-base font-bold">Mariscos La Únion</p>
          </div>
        )}
      </div>
      <div className="flex-none gap-3">
        {user && user.emailVerified ? (
          <div className="dropdown dropdown-end ">
            <div
              onClick={() => setMenuOpen(!menuOpen)}
              className="flex items-center gap-2 rounded-full bg-secondary px-2 text-white"
            >
              <button onClick={() => setMenuOpen(!menuOpen)}>
                <p className="text-base">
                  Hola {userName ? userName : defaultName}
                </p>
              </button>
              <label
                tabIndex={0}
                className="avatar btn btn-circle btn-ghost"
                onClick={() => setMenuOpen(!menuOpen)}
              >
                <div className="w-10 rounded-full">
                  <img
                    alt="avatar"
                    src={userPhoto ? userPhoto : defaultAvatar}
                  />
                </div>
              </label>
            </div>
            {menuOpen && (
              <ul
                tabIndex={0}
                className="menu dropdown-content menu-sm z-[1] mt-3 w-52 gap-4 rounded-box bg-base-100 p-2 shadow"
                onClick={() => setMenuOpen(false)}
              >
                {NavMenuLinks.map((link, index) => (
                  <NavLink
                    to={link.href !== "/logout" && link.href}
                    key={index}
                  >
                    <button
                      onClick={() => {
                        link.href === "/logout" && handleLogout();
                      }}
                      className="justify-between"
                    >
                      {link.name}
                    </button>
                  </NavLink>
                ))}
              </ul>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Navbar;
