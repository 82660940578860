import React from 'react'

const MainWindowContainer = ({children}) => {
  return (
    <div className='flex flex-col gap-4 rounded-lg bg-white p-4'>
      {children}
    </div>
  )
}

export default MainWindowContainer
