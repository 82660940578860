const useFormatTimestampDateShort = (firebaseTimestamp) => {
  if (!firebaseTimestamp) return;
  const unformattedDate = firebaseTimestamp.toDate();
  const date = unformattedDate.toLocaleDateString("es-MX", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
  return { date };
};

export default useFormatTimestampDateShort;
