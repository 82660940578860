import { Suspense } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from "../App";
import AdminGate from "../components/AdminGate";
import NotFoundPage from "../components/NotFoundPage";
import CheckTaskListPage from "../components/pages/CheckTaskListPage";
import EmployeeFrontPage from "../components/pages/EmployeeFrontPage";
import HelloPage from "../components/pages/HelloPage";
import MainWindowContainer from "../elements/MainWindowContainer";
import PrivateEmployeeRoutes from "./PrivateEmployeeRoutes";
import {
  AdminUsers,
  Beverages,
  ColdBar,
  DeleteTaskList,
  EditTaskList,
  EditUser,
  EmployeeUsers,
  Entries,
  HelperUsers,
  HomeDashboard,
  Hotbar,
  ManagerUsers,
  Menu,
  NewTaskPage,
  NewUserPage,
  Profile,
  TasksPage,
  UnassignedUsers,
  ViewTaskList,
  EditTasksPage,
  HistoryTaskPage,
} from "./LazyLoadingPages";
import PrivateRoute from "./PrivateRoutes";
import MarkTaskPage from "../components/pages/MarkTaskPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <PrivateRoute>
        <App />
      </PrivateRoute>
    ),
    errorElement: <NotFoundPage />,
    children: [
      {
        path: "/",
        element: (
          <Suspense
            fallback={<MainWindowContainer>Cargando...</MainWindowContainer>}
          >
            <HomeDashboard />
          </Suspense>
        ),
      },
      {
        path: "/barrafria",
        element: (
          <Suspense
            fallback={<MainWindowContainer>Cargando...</MainWindowContainer>}
          >
            <ColdBar />
          </Suspense>
        ),
      },
      {
        path: "/barracaliente",
        element: (
          <Suspense
            fallback={<MainWindowContainer>Cargando...</MainWindowContainer>}
          >
            <Hotbar />
          </Suspense>
        ),
      },
      {
        path: "/entradas",
        element: (
          <Suspense
            fallback={<MainWindowContainer>Cargando...</MainWindowContainer>}
          >
            <Entries />
          </Suspense>
        ),
      },
      {
        path: "/bebidas",
        element: (
          <Suspense
            fallback={<MainWindowContainer>Cargando...</MainWindowContainer>}
          >
            <Beverages />
          </Suspense>
        ),
      },
      {
        path: "/menu",
        element: (
          <Suspense
            fallback={<MainWindowContainer>Cargando...</MainWindowContainer>}
          >
            <Menu />
          </Suspense>
        ),
      },
      {
        path: "/editarusuario",
        element: (
          <Suspense
            fallback={<MainWindowContainer>Cargando...</MainWindowContainer>}
          >
            <EditUser />
          </Suspense>
        ),
      },
      {
        path: "/listadetareas",
        element: (
          <Suspense
            fallback={<MainWindowContainer>Cargando...</MainWindowContainer>}
          >
            <ViewTaskList />
          </Suspense>
        ),
      },
      {
        path: "/perfil",
        element: (
          <Suspense
            fallback={<MainWindowContainer>Cargando...</MainWindowContainer>}
          >
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          </Suspense>
        ),
      },
      {
        path: "/nuevoempleado",
        element: (
          <Suspense
            fallback={<MainWindowContainer>Cargando...</MainWindowContainer>}
          >
            <PrivateRoute>
              <NewUserPage />
            </PrivateRoute>
          </Suspense>
        ),
      },
      {
        path: "/admin",
        element: (
          <Suspense
            fallback={<MainWindowContainer>Cargando...</MainWindowContainer>}
          >
            <PrivateRoute>
              <AdminUsers />
            </PrivateRoute>
          </Suspense>
        ),
      },
      {
        path: "/empleado",
        element: (
          <Suspense
            fallback={<MainWindowContainer>Cargando...</MainWindowContainer>}
          >
            <PrivateRoute>
              <EmployeeUsers />
            </PrivateRoute>
          </Suspense>
        ),
      },
      {
        path: "/encargado",
        element: (
          <Suspense
            fallback={<MainWindowContainer>Cargando...</MainWindowContainer>}
          >
            <PrivateRoute>
              <ManagerUsers />
            </PrivateRoute>
          </Suspense>
        ),
      },
      {
        path: "/ayudante",
        element: (
          <Suspense
            fallback={<MainWindowContainer>Cargando...</MainWindowContainer>}
          >
            <PrivateRoute>
              <HelperUsers />
            </PrivateRoute>
          </Suspense>
        ),
      },
      {
        path: "/sinasignar",
        element: (
          <Suspense
            fallback={<MainWindowContainer>Cargando...</MainWindowContainer>}
          >
            <PrivateRoute>
              <UnassignedUsers />
            </PrivateRoute>
          </Suspense>
        ),
      },
      {
        path: "/tareas",
        element: (
          <Suspense
            fallback={<MainWindowContainer>Cargando...</MainWindowContainer>}
          >
            <PrivateRoute>
              <TasksPage />
            </PrivateRoute>
          </Suspense>
        ),
      },
      {
        path: "/borrarlista",
        element: (
          <Suspense
            fallback={<MainWindowContainer>Cargando...</MainWindowContainer>}
          >
            <PrivateRoute>
              <DeleteTaskList />
            </PrivateRoute>
          </Suspense>
        ),
      },
      {
        path: "/editarlista",
        element: (
          <Suspense
            fallback={<MainWindowContainer>Cargando...</MainWindowContainer>}
          >
            <PrivateRoute>
              <EditTaskList />
            </PrivateRoute>
          </Suspense>
        ),
      },
      {
        path: "/editartareas",
        element: (
          <Suspense
            fallback={<MainWindowContainer>Cargando...</MainWindowContainer>}
          >
            <PrivateRoute>
              <EditTasksPage />
            </PrivateRoute>
          </Suspense>
        ),
      },
      {
        path: "/nuevatarea",
        element: (
          <Suspense
            fallback={<MainWindowContainer>Cargando...</MainWindowContainer>}
          >
            <PrivateRoute>
              <NewTaskPage />
            </PrivateRoute>
          </Suspense>
        ),
      },
      {
        path: "/historial",
        element: (
          <Suspense
            fallback={<MainWindowContainer>Cargando...</MainWindowContainer>}
          >
            <PrivateRoute>
              <HistoryTaskPage />
            </PrivateRoute>
          </Suspense>
        ),
      },
    ],
  },
  {
    path: "/error",
    element: <NotFoundPage />,
  },
  {
    path: "/hola",
    element: <HelloPage />,
    children: [
      {
        path: "",
        element: <EmployeeFrontPage />,
      },
      {
        path: "admin",
        element: <AdminGate />,
      },
      {
        path: "tareasasignadas",
        element: (
          <PrivateEmployeeRoutes>
            <CheckTaskListPage />
          </PrivateEmployeeRoutes>
        ),
      },
      {
        path: "marcartareas",
        element: (
          <PrivateEmployeeRoutes>
            <MarkTaskPage />
          </PrivateEmployeeRoutes>
        ),
      },
    ],
  },
]);

const RoutesMap = () => {
  return <RouterProvider router={router} />;
};

export default RoutesMap;
