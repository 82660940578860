import React, { useState, useEffect } from "react";
import EmployeeNavBar from "./EmployeeNavBar";
import { Outlet } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { EmployeeProvider } from "../../context/EmployeeContext";

const HelloPage = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    if (user && user.emailVerified) navigate("/");
  }, [user, navigate]);

  const [admin, setAdmin] = useState(false);

  return (
    <>
      <EmployeeProvider>
        <EmployeeNavBar admin={admin} setAdmin={setAdmin} />
        <Outlet />
      </EmployeeProvider>
    </>
  );
};

export default HelloPage;
