import React from "react";
import ErrorAlertSmall from "../elements/ErrorAlertSmall";

const HandleLoginErrors = ({error}) => {
  switch (error) {
    case "Firebase: Error (auth/invalid-credential).":
      return (
        <ErrorAlertSmall>
          El correo o la contraseña son incorrectos
        </ErrorAlertSmall>
      );
    case "Firebase: Error (auth/invalid-email).":
      return (
        <ErrorAlertSmall>
          El correo es incorrecto o no está registrado
        </ErrorAlertSmall>
      );
    default:
      return (
        <ErrorAlertSmall>
          Ocurrió un error desconocido, inténtelo de nuevo {error.message}
        </ErrorAlertSmall>
      );
  }
  
};

export default HandleLoginErrors;
