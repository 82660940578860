export const sideBarLinks = [
  {
    title: "Panel",
    items: [
      {
        label: "Home",
        href: "",
      },
    ],
  },
  {
    title: "Menú",
    items: [
      {
        label: "Actualizar menú",
        href: "menu",
      },
    ],
  },
  {
    title: "Platillos destacados",
    items: [
      {
        label: "Barra fría",
        href: "barrafria",
      },
      {
        label: "Barra caliente",
        href: "barracaliente",
      },
      {
        label: "Entradas",
        href: "entradas",
      },
      {
        label: "Bebidas",
        href: "bebidas",
      },
    ],
  },
  {
    title: "Usuarios",
    items: [
      {
        label: "Administradores",
        href: "admin",
      },
      {
        label: "Encargado",
        href: "encargado",
      },
      {
        label: "Ayudante",
        href: "ayudante",
      },
      {
        label: "Sin asignar",
        href: "sinasignar",
      },
      {
        label: "+ Crear nuevo usuario",
        href: "nuevoempleado",
      },
    ],
  },
  {
    title: "Tareas",
    items: [
      {
        label: "Administrar tareas",
        href: "tareas",
      },
      {
        label: "Historial de tareas",
        href: "historial",
      },
    ],
  },
];
