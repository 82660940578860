import React, { createContext, useContext, useState } from "react";

const EmployeeContext = createContext();

export const useEmployee = () => useContext(EmployeeContext);

export const EmployeeProvider = ({ children }) => {
  const [loggedAsEmployee, setLoggedAsEmployee] = useState(false);
  const [employeeCred, setEmployeeCred] = useState(null);
  return (
    <EmployeeContext.Provider
      value={{
        loggedAsEmployee,
        setLoggedAsEmployee,
        employeeCred,
        setEmployeeCred,
      }}
    >
      {children}
    </EmployeeContext.Provider>
  );
};
