import React from "react";
import { NavLink } from "react-router-dom";
import { useEmployee } from "../../context/EmployeeContext";

const EmployeeNavBar = ({ admin, setAdmin }) => {
  const {
    loggedAsEmployee,
    setLoggedAsEmployee,
    setEmployeeCred,
    employeeCred,
  } = useEmployee();
  return (
    <div className="flex items-center justify-between gap-2 bg-blue-500 px-4 py-1 text-white">
      {loggedAsEmployee && (
        <div className="flex gap-4">
          <p>{employeeCred.nombre}</p>
          <p>{employeeCred.puesto}</p>
        </div>
      )}
      <NavLink to={"/hola"}>
        <button
          onClick={() => {
            setLoggedAsEmployee(false);
            setEmployeeCred(null);
          }}
          className={loggedAsEmployee ? "btn btn-warning" : "hidden"}
        >
          Cerrar Sesión
        </button>
      </NavLink>
      {!loggedAsEmployee && (
        <NavLink to={admin ? "" : "admin"}>
          <button
            className={loggedAsEmployee ? "hidden" : "btn btn-warning"}
            onClick={() => setAdmin(!admin)}
          >
            {admin ? "Empleado" : "Admin"}
          </button>
        </NavLink>
      )}
    </div>
  );
};

export default EmployeeNavBar;
