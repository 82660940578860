import React from "react";

const NumericInput = ({ value, onChange, placeholder = "Ingrese número" }) => {
  // Función para manejar cambios en el input
  const handleChange = (e) => {
    const newValue = e.target.value;
    // Permitir solo valores numéricos
    if (/^\d*$/.test(newValue)) {
      onChange(newValue); // Llama al manejador de cambio si es válido
    }
  };

  return (
    <input
      type="text"
      value={value}
      onChange={handleChange}
      placeholder={placeholder}
      className="w-[100%] rounded border p-2 focus:border-blue-500 focus:outline-none" // Agrega estilos si deseas
    />
  );
};

export default NumericInput;
