import React from "react";
import EmployeeGate from "../EmployeeGate";

const EmployeeFrontPage = () => {
  return (
    <div className="mt-8 flex justify-center">
      <div className="rounded-xl bg-white p-8 shadow-xl md:w-[50%]">
        <EmployeeGate />
      </div>
    </div>
  );
};

export default EmployeeFrontPage;
