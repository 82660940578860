import { signInWithEmailAndPassword } from "firebase/auth";
import React, { Suspense, lazy, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import ErrorAlertSmall from "../elements/ErrorAlertSmall";
import Heading from "../elements/Heading";
import { auth } from "../firebase/FirebaseConfig";
import HandleLoginErrors from "../functions/HandleLoginErrors";
import ResetPassword from "../functions/ResetPassword";

const VerifyEmailModal = lazy(() => import("../elements/VerifyEmailModal"));

const LoginForm = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [checked, setChecked] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleLogin = async (email, password) => {
    try {
      const data = await signInWithEmailAndPassword(auth, email, password);
      const user = data.user;
      setData(user);
      if (user.emailVerified) {
        navigate("/", { replace: true });
      } else {
        if (data) setChecked(true);
      }
    } catch (error) {
      console.error("Error signing in:", error.message);
      setError(error.message);
    }
  };

  return (
    <form
      onSubmit={handleSubmit((data) => {
        handleLogin(data.email, data.password);
      })}
      className="flex flex-col gap-8"
    >
      <Suspense fallback={<div>Loading...</div>}>
        {checked && (
          <VerifyEmailModal
            checked={checked}
            setChecked={setChecked}
            data={data}
          />
        )}
      </Suspense>

      <h1>Inicia sesión (Admin) </h1>
      {error && <HandleLoginErrors error={error} />}
      <div className="ml-4">
        <Heading>Correo:</Heading>
        <div className="flex flex-col gap-2">
          <input
            {...register("email", {
              required: "El correo no puede estár vacio",
            })}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="input input-bordered input-primary w-full max-w-xs text-slate-600"
            type="text"
            placeholder="Escribe tu correo"
          />
          {errors.email && (
            <ErrorAlertSmall>{errors.email.message}</ErrorAlertSmall>
          )}
        </div>
      </div>
      <div className="ml-4">
        <Heading>Contraseña:</Heading>
        <div className="flex flex-col gap-2">
          <input
            {...register("password", {
              required: "La contraseña no puede estár vacia",
              minLength: {
                value: 6,
                message: "La contraseña debe tener al menis 6 caracteres",
              },
            })}
            className="input input-bordered input-primary w-full max-w-xs text-slate-600"
            type="password"
            placeholder="Ingresa tu contraseña"
          />
          {errors.password && (
            <ErrorAlertSmall>{errors.password.message}</ErrorAlertSmall>
          )}
        </div>
      </div>
      <div className="flex flex-col items-end gap-2">
        <button className="btn btn-primary">Iniciar sesión</button>
        <p className="link-hover link" onClick={() => ResetPassword(email)}>
          ¿Olvidaste tu contraseña?
        </p>
      </div>
    </form>
  );
};

export default LoginForm;
