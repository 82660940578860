import { collection, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../firebase/FirebaseConfig";

const useGetTasks = (listID) => {
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tasksCount, setTasksCount] = useState(0);
  const [tasksDoneCount, setTasksDondeCount] = useState(0);

  useEffect(() => {
    if (!listID) return;
    const subColRef = collection(db, "listas", listID, "tareas");
    try {
      const unsubscribe = onSnapshot(subColRef, (snapshot) => {
        const tasksArray = snapshot.docs.map((task) => ({
          id: task.id,
          ...task.data(),
        }));

        // Asegúrate de que todas las tareas tienen un campo `order`.
        const tasksWithOrder = tasksArray.map((task, index) => ({
          ...task,
          order: task.order !== undefined ? task.order : index,
        }));

        // Ordena las tareas: primero por `order`, luego por `done` (no realizadas primero).
        const sortedArray = tasksWithOrder.sort((a, b) => {
          if (a.done !== b.done) {
            return a.done ? 1 : -1; // Coloca `done === true` al final
          }
          return a.order - b.order; // Si `done` es igual, ordena por `order`
        });

        // Calcula las estadísticas
        const doneTasks = sortedArray.filter((task) => task.done === true);

        setTasks(sortedArray);
        setTasksCount(tasksArray.length);
        setTasksDondeCount(doneTasks.length);
        setLoading(false);
      });

      return () => unsubscribe();
    } catch (error) {
      throw new Error(error);
    }
  }, [listID]);

  return { tasks, loading, tasksCount, tasksDoneCount };
};

export default useGetTasks;
