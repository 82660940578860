import React from "react";
import { IoIosClose } from "react-icons/io";

const TaskModal = ({ task, open, onClose, children }) => {
  return (
    <div
      onClick={onClose}
      className={`
            fixed inset-0 flex items-center justify-center transition-colors
            ${open ? "visible bg-black/30" : "invisible"}
                `}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className={`rounded-xl bg-white p-6 shadow-lg transition-all
            ${open ? "scale-100 opacity-100" : "scale-125 opacity-0"}
        `}
      >
        <button
          onClick={onClose}
          className="absolute right-2 top-2 rounded-lg bg-white p-1 text-gray-400 hover:bg-gray-50 hover:text-gray-600"
        >
          <IoIosClose size={25} />
        </button>
        {task ? children : "Cargando..."}
      </div>
    </div>
  );
};

export default TaskModal;
